import React from "react";
import Footer from "./footer";
import MainHeader from "./main_header";

import '../assets/css/main.css';

import IC_ANIMAL from '../assets/imgs/icon_animal.png';
import IC_PHONE from '../assets/imgs/button/phone.png';
import IC_MAIL from '../assets/imgs/button/mail.png';
const Provider = () => {
    return (
        <>
            <MainHeader />
            <div className="pt-[90px]">
                <iframe
                    title="map"
                    src="https://geo.medipet.pt/medipet"
                    allow="geolocation; microphone; camera; fullscreen; clipboard-write; encrypted-media; autoplay"
                    className="w-full h-full xl:hidden  min-h-[955px]"
                    
                >
                </iframe>
            </div>
            <section className="pt-[71px] hidden xl:flex flex-col justify-center" >
                <span className="font-29-600 main-blue self-center">
                    Rede de Prestadores
                </span>
                {/* <img  src={DeskTop} alt = "" /> */}
                <div className="xl:m-[48px] ">
                    <iframe
                        title="map"
                        src="https://geo.medipet.pt/medipet"
                        className="w-full h-full  min-h-[955px] xl:shadow-[0px_0px_33px_0px_#29215B17] xl:rounded-[33px]"
                        allow="geolocation; microphone; camera; fullscreen; clipboard-write; encrypted-media; autoplay"
                        
                    >
                    </iframe>
                </div>
            </section>
            <section className="flex flex-col items-center xl:flex-row xl:columns-3 justify-center mb-[100px]">
                <div className="flex flex-col  xl:mr-[119px] mt-[30px] xl:mt-0">
                    <span className="font-24-600 main-blue mb-[40px]">Precisa da nossa ajuda?</span>
                    <img src={IC_ANIMAL} alt="" className="max-w-[225px] max-h-[225px] self-center" />
                </div>
                <div className="blogs flex flex-col xl:mr-[50px] mb-[64px] xl:mb-0">
                    <img src={IC_PHONE} alt="" className="max-w-[73px] max-h-[73px] self-center mb-[19px]" />
                    <span className="font-13-400 main-blue mb-[5px]">Linha Telefónica</span>
                    <span className="font-22-700 main-blue">21 020 1238</span>
                </div>
                <div className="blogs flex flex-col ">
                    <img src={IC_MAIL} alt="" className="max-w-[73px] max-h-[73px] self-center mb-[19px]" />
                    <span className="font-13-400 main-blue mb-[5px] self-center">E-mail de Suporte:</span>
                    <span className="font-16-500 main-blue mb-[5px] self-center">prestadores@medipet.pt</span>
                    <span className="font-16-500 main-blue self-center">clientes@medipet.pt</span>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Provider;