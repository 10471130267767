import React from "react";
import Footer from "./footer";
import MainHeader from "./main_header";

import '../assets/css/main.css';
import '../assets/css/terms.css';
const Terms = () => {
    return (
        <>
            <MainHeader />

            <section className="c7 doc-content">
                <p className="c1"><span className="c11 c8">De seguida encontram-se identificados os Termos e Condi&ccedil;&otilde;es Gerais
                    do portal MediPet &ndash; www.medipet.pt. Aconselhamos a sua leitura cuidada e atenta antes de aceder e
                    utilizar os servi&ccedil;os e funcionalidades do presente portal.</span></p>
                <p className="c0"><span className="c11 c8"></span></p>
                <p className="c1"><span className="c8 c11">Os presentes Termos e Condi&ccedil;&otilde;es estabelecem como cada Utilizador
                    pode aceder e utilizar os servi&ccedil;os, os produtos e os conte&uacute;dos disponibilizados no portal
                    MediPet &ndash; www.MediPet.pt, (doravante, &ldquo;Site MediPet&rdquo;).</span></p>
                <p className="c0"><span className="c11 c12"></span></p>
                <p className="c1"><span className="c12 c18">A </span><span className="c8 c16"><a className="c10" href="#">Pol&iacute;tica de
                    Prote&ccedil;&atilde;o de Dados Pessoais</a></span><span className="c5"><a className="c10"
                        href="#">&nbsp;</a></span><span className="c11 c12">da MediPet disponibilizada Site MediPet &nbsp;faz parte
                            integrante destes Termos e Condi&ccedil;&otilde;es (doravante, em conjunto, &ldquo;T&amp;C&rdquo;).</span>
                </p>
                <p className="c1"><span className="c11 c12">&nbsp;</span></p>
                <h3 className="c1" id="h.hp1hod6npnfk"><span className="c3">&nbsp;I. Site MediPet</span></h3>
                <p className="c2"><span className="c4">O Site MediPet foi criado, pertence e &eacute; gerido pela Habit Analytics PT, S.A.,
                    (doravante &ldquo;MediPet&rdquo; que pertence &agrave; Habit(doravante, Habit) e a sua
                    utiliza&ccedil;&atilde;o pelos seus visitantes (doravante, &ldquo;Utilizadores&rdquo; ou
                    &ldquo;Utilizador&rdquo;) rege-se por estes T&amp;C.<br /><br />a MediPet &eacute; constitu&iacute;do:</span>
                </p>
                <ul className="c13 lst-kix_36gg77nqs6o9-0 start">
                    <li className="c14 li-bullet-0"><span className="c12">pela </span><span className="c8">Habit Analytics PT, S.A..
                    </span><span className="c4">com escrit&oacute;rio na Rua Julieta Ferr&atilde;o, Torre 10, 15/16 Piso, 1600-131
                        Lisboa, pessoa coletiva n&uacute;mero 504299263, (doravante designada &ldquo;MediPet&rdquo;), </span>
                    </li>
                </ul>
                <p className="c17"><span className="c4"></span></p>
                <p className="c2"><span className="c4">Neste Site MediPet, poder&aacute; encontrar conte&uacute;dos relacionados com
                    qualquer uma das empresas da MediPet.</span></p>
                <p className="c2"><span className="c4">&nbsp;</span></p>
                <h3 className="c1" id="h.dk2dylhjabe7"><span className="c3">II. &nbsp;Aceita&ccedil;&atilde;o dos T&amp;C</span></h3>
                <p className="c2"><span className="c4">Ao fazer uso das funcionalidades dispon&iacute;veis no Site MediPet, o Utilizador
                    est&aacute; a aceitar estes Termos e Condi&ccedil;&otilde;es e a &nbsp;Pol&iacute;tica de Tratamento de
                    Dados Pessoais da MediPet que deles faz parte integrante raz&atilde;o pela qual, dever&aacute; fazer uma
                    leitura cuidada e integral de ambos. </span></p>
                <p className="c2"><span className="c12">O Utilizador declara ter capacidade e legitimidade para prestar a sua
                    aceita&ccedil;&atilde;o a estes T&amp;C bem como, para assegurar o seu cumprimento. <br /><br />Caso n&atilde;o
                    aceite os presentes T&amp;C ou a Pol&iacute;tica de Prote&ccedil;&atilde;o de Dados Pessoais da MediPet, o
                    utilizador deve abster-se de utilizar o Site MediPet.<br /><br /></span></p>
                <h3 className="c1" id="h.t4cblkefiove"><span className="c3">III. Altera&ccedil;&atilde;o dos T&amp;C</span></h3>
                <p className="c2"><span className="c12">a MediPet</span><span className="c4">&nbsp;reserva-se o direito de, a qualquer momento,
                    e sem necessidade de aviso pr&eacute;vio ou notifica&ccedil;&atilde;o do Utilizador, alterar, adicionar,
                    atualizar ou eliminar, parcial ou totalmente, os T&amp;C.</span></p>
                <p className="c2"><span className="c4">Futuras vers&otilde;es desses documentos ser&atilde;o publicadas Site MediPet
                    produzindo os seus efeitos a partir desse momento. <br /><br />O Utilizador deve consultar periodicamente a
                    p&aacute;gina do Site MediPet relativa aos Termos e Condi&ccedil;&otilde;es de utiliza&ccedil;&atilde;o do
                    mesmo e &agrave; Pol&iacute;tica de Tratamento de Dados Pessoais para confirmar que conhece a sua
                    vers&atilde;o atualizada.<br /><br />As altera&ccedil;&otilde;es aos T&amp;C vinculam o Utilizador assim que
                    este aceda e utilize o Site MediPet ap&oacute;s as altera&ccedil;&otilde;es terem sido
                    efetuadas.<br /><br />Caso o Utilizador n&atilde;o concorde com alguma das regras de utiliza&ccedil;&atilde;o do
                    Site MediPet entretanto introduzidas, deve abster-se de utilizar o Site MediPet.</span></p>
                <p className="c1"><span className="c4">&nbsp;</span></p>
                <h3 className="c1" id="h.dby8xbwnhnbi"><span className="c3">IV. Propriedade Intelectual e Industrial</span></h3>
                <p className="c2"><span className="c4">O Utilizador reconhece que o Site MediPet e os conte&uacute;dos deste est&atilde;o
                    protegidos por direitos de propriedade intelectual e industrial, incluindo direitos de autor, marcas,
                    patentes, entre outros (excluindo os dados fornecidos pelos Utilizadores) que s&atilde;o propriedade
                    exclusiva de sociedades que integram a MediPet, ou de terceiros com quem as mesmas hajam contratado, e
                    compromete-se a respeitar esses direitos<br /><br />O Utilizador reconhece que a permiss&atilde;o que lhe
                    &eacute; dada pela MediPet para aceder ao Site MediPet n&atilde;o configura uma licen&ccedil;a ou direito
                    sobre o mesmo ou sobre os seus conte&uacute;dos.<br /><br />O Utilizador n&atilde;o poder&aacute; fazer
                    aproveitamento do Site MediPet ou dos seus conte&uacute;dos para criar produtos, servi&ccedil;os ou para
                    outros fins que n&atilde;o os previstos no Site MediPet, designadamente, para fins comerciais, e n&atilde;o
                    poder&aacute;, ainda, reproduzir, duplicar, copiar, transmitir ou comercializar o conte&uacute;do desse
                    website ou aplicativo, o seu design e apar&ecirc;ncia ou qualquer informa&ccedil;&atilde;o fornecida
                    atrav&eacute;s do mesmo.<br /><br />Ocorrendo uma viola&ccedil;&atilde;o de direitos de Propriedade Intelectual
                    ou Industrial ou existindo uma suspeita fundada de que ir&aacute; ocorrer essa viola&ccedil;&atilde;o, a
                    MediPet poder&aacute; cancelar as contas do Utilizador que seja respons&aacute;vel pela mesma e recorrer aos
                    meios judiciais e extrajudiciais necess&aacute;rios &agrave; prote&ccedil;&atilde;o desses direitos e ao
                    ressarcimento dos preju&iacute;zos decorrentes da referida viola&ccedil;&atilde;o.<br /><br />a MediPet autoriza
                    a consulta, o download e a impress&atilde;o dos conte&uacute;dos do Site MediPet pelo Utilizador, desde que
                    sejam respeitadas as seguintes condi&ccedil;&otilde;es:<br /><br />(i) Utiliza&ccedil;&atilde;o estritamente
                    para fins pessoais e n&atilde;o comerciais; <br />(ii) N&atilde;o modifica&ccedil;&atilde;o de
                    conte&uacute;dos;<br />(iii) N&atilde;o elimina&ccedil;&atilde;o ou modifica&ccedil;&atilde;o de
                    men&ccedil;&otilde;es relativas a direito de propriedade, a marca comercial ou avisos relativos &agrave;
                    propriedade expressos nos conte&uacute;dos do Site MediPet;<br />(iv) N&atilde;o produ&ccedil;&atilde;o,
                    reprodu&ccedil;&atilde;o ou distribui&ccedil;&atilde;o de trabalhos baseados nos conte&uacute;dos do Site
                    MediPet, salvo quando expressamente autorizado pelo &nbsp;MediPet;<br /><br />Verificadas as
                    condi&ccedil;&otilde;es descritas, qualquer imagem ou impress&atilde;o dos conte&uacute;dos do Site MediPet
                    dever&aacute; mencionar &ldquo;&copy; 2024, MediPet todos os direitos reservados.&rdquo;</span></p>
                <p className="c2"><span className="c4">&nbsp;</span></p>
                <h3 className="c1" id="h.vgh8v3wh0si7"><span className="c3">V. &nbsp;Funcionalidades disponibilizadas</span></h3>
                <p className="c2"><span className="c12">Atrav&eacute;s do site MediPet, s&atilde;o disponibilizadas ao utilizador
                    v&aacute;rias funcionalidades, tais como:<br /><br />(i) </span><span className="c8">Consultar a Biblioteca de
                        Sa&uacute;de</span><span className="c12">&nbsp;- &nbsp;Na Biblioteca de sa&uacute;de s&atilde;o disponibilizados
                            conte&uacute;dos e artigos sobre sa&uacute;de, seguros e bem-estar.<br />(ii) </span><span
                                className="c8">Consultar Gloss&aacute;rio</span><span className="c12">&nbsp;- O Gloss&aacute;rio &eacute; um
                                    dicion&aacute;rio sobre termos relacionados com sa&uacute;de.<br />(iii) </span><span className="c8">Consultar o
                                        Diret&oacute;rio Cl&iacute;nico</span><span className="c12">&nbsp;- O Diret&oacute;rio cl&iacute;nico permite
                                            pesquisar um m&eacute;dico ou prestador da rede MediPet.<br />(iv) </span><span
                                                className="c8">Subscri&ccedil;&atilde;o de Newsletters</span><span className="c12">&nbsp;- As Newsletters s&atilde;o
                                                    Informa&ccedil;&otilde;es peri&oacute;dicas sobre temas da atualidade na &aacute;rea da sa&uacute;de, com
                                                    prop&oacute;sitos meramente informativos, que s&atilde;o gratuitamente remetidas para o e-mail do utilizador
                                                    que as subscrever. O utilizador subscreve a Newsletters, quando clica no bot&atilde;o
                                                    &ldquo;Subscrever&rdquo; e introduz o e-mail para onde as mesmas devem ser enviadas. O envio de Newsletters
                                                    pode ser cancelado a todo o tempo clicando no bot&atilde;o Remover dispon&iacute;vel em cada e-mail
                                                    enviado.<br />(v) </span><span className="c8">Apresentar um pedido nos formul&aacute;rios digitais</span><span
                                                        className="c4">&nbsp;- Para os tomadores de Seguros, Segurados, Benefici&aacute;rios ou Terceiros lesados, bem
                        como para qualquer outro cliente e/ou Utilizador do Site MediPet, a MediPet disponibiliza um conjunto de
                        formul&aacute;rios para submiss&atilde;o de pedidos como sejam esclarecimentos, apresenta&ccedil;&atilde;o
                        de reclama&ccedil;&otilde;es, pr&eacute;-autoriza&ccedil;&otilde;es, candidaturas, entre outros. </span></p>
                <p className="c2"><span className="c12">(vii) </span><span className="c8">Chatbot </span><span className="c4">- Disponibilizamos
                    para o p&uacute;blico em geral, uma ferramenta de comunica&ccedil;&atilde;o que permite acedera
                    informa&ccedil;&atilde;o relevante sobre o que fazemos e como fazemos. Em todo o caso, algumas
                    funcionalidades poder&atilde;o estar limitadas a tomadores de seguro, ou segurados e/ou sujeitas a
                    autentica&ccedil;&atilde;o pr&eacute;via. Sempre qye adequado poderemos encaminh&aacute;-lo para contacto
                    direto comum dos nossos elementos da equipa de atendimento. </span></p>
                <p className="c2"><span className="c4">A MediPet reserva-se o direito de, a qualquer momento, e sem necessidade de aviso
                    pr&eacute;vio ou notifica&ccedil;&atilde;o do Utilizador, alterar, adicionar, atualizar ou eliminar, parcial
                    ou totalmente, as funcionalidades do Site MediPet e os respetivos conte&uacute;dos ou de interromper ou
                    suspender o funcionamento desse site.</span></p>
                <p className="c2"><span className="c4">&nbsp;</span></p>
                <h3 className="c1" id="h.bemecsno7dmn"><span className="c3">VI. Responsabilidade</span></h3>
                <p className="c2"><span className="c4">O Site MediPet foi desenvolvido pela MediPet com o objetivo de informar e esclarecer
                    os Utilizadores sobre atividades desenvolvidas pela MediPet e/ou de permitir o acesso a funcionalidades
                    especificas.<br /><br />A MediPet informa que as informa&ccedil;&otilde;es prestadas do Site MediPet t&ecirc;m
                    carater meramente indicativo e que, apesar de terem sido objeto de uma an&aacute;lise minuciosa, podem
                    conter erros ou imprecis&otilde;es.<br /><br />a MediPet n&atilde;o assume a responsabilidade por qualquer
                    incorre&ccedil;&atilde;o ou omiss&atilde;o das informa&ccedil;&otilde;es contidas no Site MediPet e qualquer
                    decis&atilde;o baseada nessas mesmas informa&ccedil;&otilde;es &eacute; da exclusiva responsabilidade do
                    Utilizador.<br /><br />Dentro dos limites legais, as sociedades da MediPet, os seus colaboradores, agentes,
                    parceiros e licenciadores rejeitam qualquer responsabilidade por quaisquer danos diretos ou indiretos,
                    incluindo perdas de dados e lucros cessantes. que resultem do acesso ao Site MediPet, da sua
                    utiliza&ccedil;&atilde;o ou de qualquer defeito, v&iacute;rus, ataques de hackers ou outros softwares de
                    intrus&atilde;o levados a cabo por terceiros, do mau funcionamento do equipamento, do uso de qualquer
                    software, do seu mau uso, ou de reclama&ccedil;&otilde;es de terceiros relacionadas com o seu uso ou de
                    eventuais situa&ccedil;&otilde;es de indisponibilidade, impossibilidade, atraso, suspens&atilde;o ou
                    interrup&ccedil;&atilde;o no acesso e utiliza&ccedil;&atilde;o do Site MediPet.<br /><br />O Utilizador
                    reconhece que a utiliza&ccedil;&atilde;o que fa&ccedil;a do Site MediPet &eacute; por sua conta e risco e
                    que &eacute; o &uacute;nico respons&aacute;vel por qualquer dano que resulte dessa utiliza&ccedil;&atilde;o,
                    incluindo danos causados ao seu sistema e/ou equipamento inform&aacute;tico ou perda ou
                    danifica&ccedil;&atilde;o de dados ou danos que resultem dos materiais, conte&uacute;dos ou
                    informa&ccedil;&otilde;es obtidas, por qualquer forma, atrav&eacute;s do Site MediPet.</span></p>
                <p className="c2"><span className="c4">&nbsp;</span></p>
                <h3 className="c1" id="h.rhxvt41d5sy2"><span className="c3">VII. Dados Pessoais</span></h3>
                <p className="c2"><span className="c4">A utiliza&ccedil;&atilde;o do Site MediPet n&atilde;o implica necessariamente o
                    fornecimento ou tratamento de dados pessoais, no entanto, sempre que tal se verifique, ser&aacute;
                    aplic&aacute;vel a Pol&iacute;tica de Tratamento de &nbsp;Dados Pessoais da MediPet</span></p>
                <p className="c2"><span className="c4">&nbsp;</span></p>
                <h3 className="c1" id="h.8587lvae7kk3"><span className="c3">VIII. Liga&ccedil;&atilde;o a outros entidades ou
                    conte&uacute;dos</span></h3>
                <p className="c2"><span className="c4">O Site MediPet poder&aacute; incluir links para outras p&aacute;ginas de terceiros
                    que n&atilde;o pertencem aa MediPet. <br /><br />a MediPet n&atilde;o aceita qualquer responsabilidade pela
                    disponibilidade desses Links ou pela exatid&atilde;o dos conte&uacute;dos acedidos pelo Utilizador
                    atrav&eacute;s dos mesmos, que n&atilde;o foram aprovados, nem endossados pela MediPet . <br /><br />O
                    Utilizador assume inteira responsabilidade pelo acesso a esses links e pela utiliza&ccedil;&atilde;o dos
                    conte&uacute;dos a que acedeu atrav&eacute;s dos mesmos.</span></p>
                <p className="c1"><span className="c4">&nbsp;</span></p>
                <h3 className="c1" id="h.ill5gc8gm8sr"><span className="c3">IX. Lei Aplic&aacute;vel e Jurisdi&ccedil;&atilde;o</span></h3>
                <p className="c2"><span className="c4">Os T&amp;C est&atilde;o sujeitos &agrave; legisla&ccedil;&atilde;o vigente no
                    ordenamento jur&iacute;dico portugu&ecirc;s. Para a resolu&ccedil;&atilde;o de quaisquer conflitos
                    emergentes dos T&amp;C, fixam-se como competentes os tribunais da comarca de Lisboa, com expressa renuncia a
                    qualquer outro.<br /><br />No caso de alguma disposi&ccedil;&atilde;o dos T&amp;C vir a ser declarada
                    inv&aacute;lida, ainda que apenas parcialmente, tal n&atilde;o implica a invalidade das restantes
                    disposi&ccedil;&otilde;es nem prejudicar&aacute; a sua validade como um todo.</span></p>
                <p className="c2"><span className="c4">&nbsp;</span></p>
                <h3 className="c1" id="h.n169gq96srqs"><span className="c3">X. Quest&otilde;es e sugest&otilde;es</span></h3>
                <p className="c2"><span className="c4">Caso tenha alguma quest&atilde;o sobre os T&amp;C, por favor envie-nos o seu pedido
                    de esclarecimento atrav&eacute;s do e-mail cliente.compliance@MediPet.pt ou por carta para a morada da
                    MediPet.</span></p>
                <p className="c2"><span className="c4"><br />Os T&amp;C foram revistos e publicados em Setembro de 2024</span></p>
                <p className="c6"><span className="c9"></span></p>
            </section>

            <Footer />
        </>
    )
}

export default Terms;