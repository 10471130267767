import React from "react";
import Footer from "./footer";
import MainHeader from "./main_header";
import '../assets/css/main.css';
import '../assets/css/privacy.css';

const Privacy = () => {
    
    return (
        <>
            <MainHeader />

            <section className="privacy_c21 doc-content" >
                <h4 className="privacy_c1" id="h.3b9el58n1jh4"><span className="privacy_c5">A MediPet garante a salvaguarda do direito da
                    prote&ccedil;&atilde;o dos dados pessoais, nos termos do disposto na legisla&ccedil;&atilde;o em vigor,
                    sendo os dados tratados de forma confidencial, atrav&eacute;s da aplica&ccedil;&atilde;o de medidas,
                    t&eacute;cnicas e organizativas, para proteger os mesmos.</span></h4>
                <p className="privacy_c1"><span className="privacy_c7">&nbsp;</span></p>
                <h2 className="privacy_c1" id="h.3cjxvrgedmrg"><span className="privacy_c11">Gest&atilde;o de dados pessoais pela MediPet</span></h2>
                <p className="privacy_c1"><span className="privacy_c2">&nbsp;</span></p>
                <h3 className="privacy_c1" id="h.nz9xo42j6q5f"><span className="privacy_c3">Respons&aacute;vel pelo Tratamento</span></h3>
                <p className="privacy_c0"><span className="privacy_c2">Habit Analytics PT, S.A.<br/>Rua Julieta Ferr&atilde;o, Torre 10, 15 piso, 1600-131
                    Lisboa<br/></span></p>
                    <h3 className="privacy_c1" id="h.vk3kffeynbub"><span className="privacy_c3">Encarregado de Prote&ccedil;&atilde;o de dados</span></h3>
                    <p className="privacy_c0"><span className="privacy_c12">Habit Analytics PT, S.A.<br/>Rua Julieta Ferr&atilde;o, Torre 10, 15 piso, 1600-131
                        Lisboa</span></p>
                    <p className="privacy_c0"><span className="privacy_c2">&nbsp;</span></p>
                    <h3 className="privacy_c1" id="h.x62c5t12jq1x"><span className="privacy_c3">Direitos do Titular de Dados</span></h3>
                    <p className="privacy_c0"><span className="privacy_c12">O titular de dados poder&aacute;, em qualquer momento, exercer os seus
                        direitos.</span></p>
                    <p className="privacy_c0"><span className="privacy_c2">&nbsp;</span></p>
                    <h3 className="privacy_c1" id="h.ro5cgphd121n"><span className="privacy_c3">Pol&iacute;tica de Privacidade</span></h3>
                    <p className="privacy_c0"><span className="privacy_c12">Damos a conhecer a nossa Pol&iacute;tica de Prote&ccedil;&atilde;o de Dados
                        Pessoais. </span></p>
                    <p className="privacy_c0"><span className="privacy_c2">&nbsp;</span></p>
                    <h4 className="privacy_c1" id="h.4mfvfxn83d9a"><span className="privacy_c5">Cookies</span></h4>
                    <p className="privacy_c0"><span className="privacy_c12">A MediPet utiliza Cookies neste portal de forma a melhorar a experi&ecirc;ncia do
                        Utilizador. Apresentamos a nossa Politica de Cookies.</span></p>
                    <p className="privacy_c0"><span className="privacy_c2">&nbsp;</span></p>
                    <h3 className="privacy_c1" id="h.lsl7xp2tiyvg"><span className="privacy_c3">Finalidades de tratamento</span></h3>
                    <p className="privacy_c0"><span className="privacy_c5">&nbsp;</span></p>
                    <p className="privacy_c0"><span className="privacy_c5">Na utiliza&ccedil;&atilde;o deste site o titular tem &agrave; sua
                        disposi&ccedil;&atilde;o a utiliza&ccedil;&atilde;o de v&aacute;rios formul&aacute;rios, que utiliza em seu
                        nome ou por conta de terceiros, sendo recolhidos dados pessoais atrav&eacute;s dos mesmos.</span></p>
                    <p className="privacy_c0"><span className="privacy_c2">Para cada um dos formul&aacute;rios existem finalidades de tratamento
                        espec&iacute;ficas bem como prazos e crit&eacute;rios de reten&ccedil;&atilde;o dos mesmos, propondo-se a
                        MediPet em identificar o detalhe dessas finalidades.</span></p>
                    <p className="privacy_c0"><span className="privacy_c2">No &acirc;mbito da presta&ccedil;&atilde;o dos nossos servi&ccedil;os tratamos dados
                        dos nossos clientes diretos no &acirc;mbito diferentes finalidades.</span></p>
                    <p className="privacy_c0"><span className="privacy_c2">O detalhe referente a estas finalidades encontra-se dispon&iacute;vel na nossa
                        Politica de Privacidade.</span></p>
                    <table className="privacy_c23">
                        <tr className="privacy_c22">
                            <td className="privacy_c6" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c14">Dados coletados </span></p>
                            </td>
                            <td className="privacy_c4" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c14">Finalidade</span></p>
                            </td>
                        </tr>
                        <tr className="privacy_c19">
                            <td className="privacy_c6" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c2">O seu nome, data de nascimento, dados de identifica&ccedil;&atilde;o e
                                    dados de contacto (endere&ccedil;o de e-mail, n&uacute;mero de telefone e morada, entre
                                    outros)</span></p>
                            </td>
                            <td className="privacy_c4" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c2">Para a cria&ccedil;&atilde;o de uma Conta nas nossas Plataformas
                                    online</span></p>
                            </td>
                        </tr>
                        <tr className="privacy_c19">
                            <td className="privacy_c6" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c2">O seu nome, data de nascimento, dados de identifica&ccedil;&atilde;o,
                                    n&uacute;mero de contribuinte e dados de contacto (endere&ccedil;o de email, numero de telefone,
                                    morada, entre outros)</span></p>
                            </td>
                            <td className="privacy_c4" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c2">Para realiza&ccedil;&atilde;o ou cancelamento das suas compras no nossa
                                    loja online </span></p>
                            </td>
                        </tr>
                        <tr className="privacy_c10">
                            <td className="privacy_c6" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c2">Informa&ccedil;&otilde;es sobre as suas transa&ccedil;&otilde;es,
                                    incluindo dados do seu cart&atilde;o de pagamento</span></p>
                            </td>
                            <td className="privacy_c4" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c2">De forma a podermos processar pagamentos realizados no &acirc;mbito da
                                    nossa loja online</span></p>
                            </td>
                        </tr>
                        <tr className="privacy_c10">
                            <td className="privacy_c6" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c2">Dados fornecidos por si quando apresenta uma reclama&ccedil;&atilde;o,
                                    sugest&atilde;o ou pedido de informa&ccedil;&atilde;o </span></p>
                            </td>
                            <td className="privacy_c4" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c2">Para que possamos processar o seu pedido</span></p>
                            </td>
                        </tr>
                        <tr className="privacy_c17">
                            <td className="privacy_c6" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c2">As comunica&ccedil;&otilde;es que troca connosco (por exemplo, os seus
                                    e-mails, cartas ou chamadas para o Contact Center) </span></p>
                            </td>
                            <td className="privacy_c4" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c2">Quando entra em contacto com a MediPet ou &eacute; contactado por
                                    n&oacute;s, designadamente para o informar sobre as suas compras da nossa loja online ou um
                                    pedido que efetuou aos nossos servi&ccedil;os</span></p>
                            </td>
                        </tr>
                        <tr className="privacy_c10">
                            <td className="privacy_c6" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c2">As suas publica&ccedil;&otilde;es e mensagens nas redes sociais dirigidas
                                    &agrave; MediPet </span></p>
                            </td>
                            <td className="privacy_c4" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c2">Quando interage connosco nas redes sociais &nbsp;</span></p>
                            </td>
                        </tr>
                        <tr className="privacy_c18">
                            <td className="privacy_c6" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c2">Informa&ccedil;&otilde;es sobre como usa as nossas Plataformas </span>
                                </p>
                            </td>
                            <td className="privacy_c4" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c12">Quando utiliza as nossas Plataformas, nos termos da nossa </span><span
                                    className="privacy_c14 c20"><a className="privacy_c9"
                                        href="https://www.google.com/url?q=https://medipet.pt&amp;sa=D&amp;source=editors&amp;ust=1730130198793125&amp;usg=AOvVaw0WZE2f4GXxyhnkInv9YFO7">Pol&iacute;tica
                                        de Cookies e outras Tecnologias de Rastreio</a></span></p>
                            </td>
                        </tr>
                        <tr className="privacy_c19">
                            <td className="privacy_c6" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c2">Nome, tipo de prestador, designa&ccedil;&atilde;o social, NIF,
                                    especialidades, dados de contacto (email, morada e contacto telef&oacute;nico)</span></p>
                            </td>
                            <td className="privacy_c4" colspan="1" rowspan="1">
                                <p className="privacy_c8"><span className="privacy_c2">Os dados fornecidos ser&atilde;o tratados para avaliar a candidatura
                                    apresentada &agrave; rede de prestadores da MediPet e entrar em contacto consigo caso
                                    necess&aacute;rio neste &acirc;mbito</span></p>
                            </td>
                        </tr>
                    </table>
                    <p className="privacy_c1"><span className="privacy_c2">&nbsp;</span></p>
                    <p className="privacy_c0"><span className="privacy_c2">Alguns dados pessoais s&atilde;o de fornecimento obrigat&oacute;rio pelo que, em caso
                        de falta ou insufici&ecirc;ncia desses dados, a MediPet n&atilde;o poder&aacute; disponibilizar o acesso ou
                        efetivar o pedido solicitado. Nestes casos o Cliente ser&aacute; devidamente informado da obrigatoriedade do
                        fornecimento desses dados.</span></p>
                    <p className="privacy_c0"><span className="privacy_c2">A &nbsp;MediPet trata dados no &acirc;mbito de outras finalidades para al&eacute;m
                        das identificadas no entanto para essas finalidades atua enquanto subcontratante de seguradoras/entidades,
                        que contratam a MediPet para a gest&atilde;o de seguros/planos de sa&uacute;de, avalia&ccedil;&atilde;o de
                        risco &agrave; subscri&ccedil;&atilde;o de seguros de sa&uacute;de/vida e gest&atilde;o de danos
                        corporais.</span></p>
                    <p className="privacy_c0"><span className="privacy_c2">Neste &acirc;mbito a MediPet trata os seus dados de acordo com as
                        instru&ccedil;&otilde;es das seguradoras/entidades para os efeitos de finalidades definidas por
                        estas.</span></p>
                    <p className="privacy_c8 c15"><span className="privacy_c16"></span></p>
                </section>

                    <Footer />
                </>
                )
}

                export default Privacy;