import React from "react";
import Footer from "./footer";
import Header from "./header";

import '../assets/css/main.css';
import Banner from '../assets/imgs/banner.png';
import Banner_sp from '../assets/imgs/banner-sp.png';
import Logo from '../assets/imgs/Logo_MediPet.png';
import subTitle from '../assets/imgs/banner_title.png';
import ICON1 from '../assets/imgs/Icon_stethoscope.png';
import ICON2 from '../assets/imgs/Icon_pills.png';
import ICON3 from '../assets/imgs/Icon_scissors.png';
import ICON4 from '../assets/imgs/Icon_law.png';
import ICON5 from '../assets/imgs/Icon_bot.png';
import IC_ARROW from '../assets/imgs/button/ic_arrow.png';
import IC_MAP from '../assets/imgs/map.png';
import IC_MAP_SP from '../assets/imgs/map-sp.png';
import IC_HEART from '../assets/imgs/heart.png';
import IC_ANIMAL from '../assets/imgs/icon_animal.png';
import IC_PHONE from '../assets/imgs/button/phone.png';
import IC_MAIL from '../assets/imgs/button/mail.png';
import Main_BG from '../assets/imgs/main-bg.png';
import Main_BG_SP from '../assets/imgs/main-bg-sp.png';
import { useRouter } from '../router/hooks/index';
const Main = () => {
    const router = useRouter();
    const goProvider = () => {
        router.push('/provider');
    }
    const onRegist = () => {
        window.location.href = "https://registo.medipet.pt";
    }
    return (
        <>
            <Header/>
            <section className= " bg-cover bg-center xl:mb-[50px] xl:h-screen h-full mb-[50px]">
                <div className="flex flex-col xl:flex-row columns-2 gap-2 w-full">
                    
                    <div className=" xl:pb-11  xl:items-start flex flex-col xl:px-[90px] px-0 items-center pb-0 w-full">
                        <img src={Logo} alt="" className="xl:mb-[26px] xl:max-w-[350px]  mt-[28px] max-w-[262px] w-full" />
                        <img src={subTitle} alt="" className="xl:max-w-[11rem] xl:w-full xl:mb-[55px] mt-[19px] mb-[39px]" />
                        <span className="main-blue  xl:max-w-[500px] xl:mb-[36px] font-31-500 max-w-[314px] mb-[21px] xl:text-left ">Tudo o que o seu animal precisa, numa só Rede</span>
                        <span className=" main-grey xl:max-w-[476px] font-16-400 max-w-[314px] text-center xl:text-left">Trabalhamos com os melhores especialistas e serviços para cuidar do seu animal. Explore a nossa rede e descubra as melhores opções para o seu amigo de quatro patas.</span>
                    </div>
                    <div className="xl:w-[55%]  h-full xl:shrink-0 w-full">
                        <img src={Banner} alt="" className=" hidden xl:block h-auto max-h-screen w-full aspect-[863 / 726] object-right-top object-contain"/>
                        <img src={Banner_sp} alt="" className="xl:hidden w-full h-full"/>
                    </div>
                </div>
            </section>
            <section className="xl:mb-[90px] mb-[50px]">
                <div className="grid grid-cols-2 gaps-2 xl:flex xl:flex-row xl:columns-5 xl:justify-evenly ">
                    <div className="flex flex-col  items-center mb-[36px]">
                        <div className="max-w-[119px] max-h-[119px] xl:max-w-[185px] xl:max-h-[185px] bg-[#F2F5F8] rounded-[49px] mb-[36px]">
                            <img alt="" src={ICON1} className="xl:p-[30px] p-[15px] w-full h-full"  />
                        </div>
                        <span className="font-14-700 main-blue text-center">HOSPITAIS E CLÍNICAS <br></br>VETERINÁRIAS </span>
                    </div>
                    <div className="flex flex-col  items-center mb-[36px]">
                        <div className="max-w-[119px] max-h-[119px] xl:max-w-[185px] xl:max-h-[185px] bg-[#F2F5F8] rounded-[49px] mb-[36px]">
                            <img alt="" src={ICON2} className="xl:p-[30px] p-[15px] w-full h-full" />
                        </div>
                        <span className="font-14-700 main-blue text-center">MEDICAMENTOS </span>
                    </div>
                    <div className="flex flex-col  items-center mb-[36px]">
                        <div className="max-w-[119px] max-h-[119px] xl:max-w-[185px] xl:max-h-[185px] bg-[#F2F5F8] rounded-[49px] mb-[36px]">
                            <img alt="" src={ICON3} className="xl:p-[30px] p-[15px] w-full h-full" />
                        </div>
                        <span className="font-14-700 main-blue text-center"> BEM-ESTAR</span>
                    </div>
                    <div className="flex flex-col  items-center mb-[36px]">
                        <div className="max-w-[119px] max-h-[119px] xl:max-w-[185px] xl:max-h-[185px] bg-[#F2F5F8] rounded-[49px] mb-[36px]">
                            <img alt="" src={ICON4} className="xl:p-[30px] p-[15px] w-full h-full" />
                        </div>
                        <span className="font-14-700 main-blue text-center">ESCOLAS, CRECHES  <br></br>E HOTÉIS </span>
                    </div>
                    <div className="flex flex-col  items-center mb-[36px]">
                        <div className="max-w-[119px] max-h-[119px] xl:max-w-[185px] xl:max-h-[185px] bg-[#F2F5F8] rounded-[49px] mb-[36px]">
                            <img alt="" src={ICON5} className="xl:p-[30px] p-[15px] w-full h-full" />
                        </div>
                        <span className="font-14-700 main-blue text-center">ALIMENTAÇÃO <br></br> </span>
                    </div>
                </div>
            </section>
            <section className="flex flex-col xl:flex-row xl:column-2 mb-[183px] justify-between">
                <img src={IC_MAP_SP} alt="" className="xl:hidden block ml-[50px] mr-[50px]"/>

                <div className="flex flex-col mt-[35px] ml-[18px] mr-[18px] text-center xl:pl-[126px] xl:gap-[70px] xl:mt-[61px]">
                    <span className="font-21-600 mb-[26px] xl:max-w-[450px] xl:font-29-600 main-blue">Pesquise agora na nossa rede aquilo que pretende para o seu animal.</span>
                    <button className="cta-button flex items-center justify-center self-center" onClick={()=>goProvider()}> 
                        <span className="font-18-900 mr-2">VER REDE DE PRESTADORES</span>
                        <img src={IC_ARROW} alt="arrow icon" className="arrow-icon" />
                    </button>
                </div>
                <div>
                    <img src={IC_MAP} alt="" className="hidden xl:block"/>
                </div>
            </section>
            <section className=" h-full xl:mr-[75px] mb-[158px] flex xl:flex-row flex-col xl:middle-bg middle-bg-sp xl:max-h-[542px]">  
       
                <img src={Main_BG} alt="" className="mr-[89px] w-[50%] hidden xl:flex max-h-[542px] object-cover"/>
      
                <img src={Main_BG_SP} alt="" className="xl:hidden p-[64px]" />
                <div className=" flex flex-col   w-full h-full pl-[22px] xl:pl-0 pb-[103px] xl:pb-0 ">
                    <span className="font-29-700  main-blue mt-[53px] max-w-[730px]" >Junte-se à nossa Rede de Prestadores!</span>
                    <span className="font-18-500  main-blue mt-[15px] max-w-[730px]">Reforce a missão global de proporcionar o melhor cuidado e atenção a animais. </span>

                    <span className="font-16-400  main-grey mt-[40px] flex flex-row"><img src={IC_HEART} alt="" className="mr-[13px] max-w-[14x] max-h-[12px] self-center"/> Sem qualquer custo de partipação</span>
                    <span className="font-16-400  main-grey mt-[10px] flex flex-row"><img src={IC_HEART} alt="" className="mr-[13px] max-w-[14x] max-h-[12px] self-center"/> Processo ágil e descomplicado</span>
                    <span className="font-16-400  main-grey mt-[10px] flex flex-row"><img src={IC_HEART} alt="" className="mr-[13px] max-w-[14x] max-h-[12px] self-center"/> Pagamentos Automáticos em 24 horas</span>
                    <span className="font-16-400  main-grey mt-[10px] flex flex-row"><img src={IC_HEART} alt="" className="mr-[13px] max-w-[14x] max-h-[12px] self-center"/> Bónus em função do número de clientes recebidos</span>
                    <span className="font-16-400  main-grey mt-[10px] flex flex-row"><img src={IC_HEART} alt="" className="mr-[13px] max-w-[14x] max-h-[12px] self-center"/>Acesso à base de clientes das maiores empresas em Portugal</span>

                    <button className="second-button flex xl:items-center justify-center mt-[39px] mb-[44px] self-center xl:self-start " onClick={() => {onRegist()}}>
                        <span className="font-18-900 mr-2">JUNTE-SE A NÓS</span>
                    </button>
                </div>
            </section>
            <section className="flex flex-col items-center xl:flex-row xl:columns-3 justify-center mb-[100px]">
                <div className="flex flex-col  xl:mr-[119px] mt-[30px] xl:mt-0">
                    <span className="font-24-600 main-blue mb-[40px]">Precisa da nossa ajuda?</span>
                    <img src={IC_ANIMAL} alt="" className="max-w-[225px] max-h-[225px] self-center"/>
                </div>
                <div className="blogs flex flex-col xl:mr-[50px] mb-[64px] xl:mb-0">
                    <img src={IC_PHONE} alt="" className="max-w-[73px] max-h-[73px] self-center mb-[19px]"/>
                    <span className="font-13-400 main-blue mb-[5px]">Linha Telefónica</span>
                    <span className="font-22-700 main-blue">21 020 1238</span>
                </div>
                <div className="blogs flex flex-col ">
                    <img src={IC_MAIL} alt="" className="max-w-[73px] max-h-[73px] self-center mb-[19px]"/>
                    <span className="font-13-400 main-blue mb-[5px] self-center">E-mail de Suporte:</span>
                    <a href="prestadores@medipet.pt" className="font-16-500 main-blue mb-[5px] self-center">prestadores@medipet.pt</a>
                    <a href="clientes@medipet.pt" className="font-16-500 main-blue self-center">clientes@medipet.pt</a>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default Main;